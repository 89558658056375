import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import useApi from "../../hooks/useApi";
//libs
import { format } from 'date-fns'
import { he } from "date-fns/locale";
// Mui
import { Box, Typography, TextField, Card, Table, TableHead, TableRow, TableBody, Container, LinearProgress, CircularProgress, Autocomplete, IconButton, styled } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import NoHistory from '../../assets/NoHistory.svg'
//api
import { getPeriods, getRefundAPI } from 'api/api';
//components
import { permanentInfo } from "data/permanentInfo";
import { MuiTableCell } from "pages/parentHistoryPages/sharedHistoryComponents/HistoryComponents"
import { InputStyle, MuiTableContainer, SearchInput } from "pages/ActivitiesReport";

const MyInputStyle = styled(TextField)(({ theme, ...params }) => ({
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        width: params.date ? '11vw' : '15vw',
        minWidth: '8rem',
        borderRadius: '25px',
        transition: "all 200ms ease-in-out",
        marginBottom: '5px',
        marginRight: params.date1 ? '0.5rem' : '0rem',
        background: "#fff",
        minHeight: '2.5rem',
        maxHeight: '2.5rem',
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        ":after,:before": {
            borderBottom: 'none !important'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
            borderWidth: '1.5px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: '28px !important',
            width: '25vw',
            "& .MuiInputBase-input": {
                fontSize: '0.6rem'
            },
        },
    }
}));

const TribeRefundsPage = ({ tribe }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [refundList, setRefundsList] = useState([]);
    const [periodsList, setPeriodsList] = useState([]);
    const [activeList, setActiveList] = useState([]);
    const [choosenActivites, setChoosenActivites] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [currentPeriod, setCurrentPeriod] = useState({ PeriodCode: "0", PeriodName: "לא נמצאה תקופה" });
    const getAllRefunds = useApi(getRefundAPI);
    const periods = useApi(getPeriods);

    useEffect(() => {
        let isMounted = true;
        isMounted && handlePeriods();
        isMounted && getRefundsList();
        return () => { isMounted = false }
    }, []);

    // get refundList
    useEffect(() => {
        let isMounted = true;
        isMounted && getRefundsList();
        return () => { isMounted = false }
    }, [tribe, currentPeriod]);

    useEffect(() => {
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        setStartDate(firstDayOfMonth);
        setEndDate(lastDayOfMonth);
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 350);

        return () => {
            clearTimeout(handler); // Cleanup the timeout on component unmount or searchTerm change
        };
    }, [searchTerm]);

    useEffect(() => {
        const uniqueActivCodesMap = new Map();

        if (refundList) {
            refundList.forEach(refund => {
                if (!uniqueActivCodesMap.has(refund.ActivCode)) {
                    uniqueActivCodesMap.set(refund.ActivCode, refund.ActivName);
                }
            });

            // Create an empty array and push objects manually from the Map
            const uniqueActivCodes = [];
            uniqueActivCodesMap.forEach((ActivName, ActivCode) => {
                uniqueActivCodes.push({ ActivName, ActivCode });
            });
            setActiveList(uniqueActivCodes);
        }
    }, [refundList]);

    const handlePeriods = async () => {
        const periodApi = await periods.requestPromise();
        let periodsArray = [];
        if (periodApi.d.results.length >= 2) {
            periodsArray = periodApi.d.results.slice(-2);
        } else {
            periodsArray = periodApi.d.results;
        }
        const noPeriodObject = { PeriodCode: "0", PeriodName: "לא נמצאה תקופה" };
        const choosenPeriod = periodsArray.length ? periodsArray[periodsArray.length - 1] : noPeriodObject;
        setCurrentPeriod(choosenPeriod);
        setPeriodsList(periodsArray);
    }
    const getRefundsList = async () => {
        let isMounted = true;
        try {
            const refunds = await getAllRefunds.requestPromise(
                {
                    "PeriodCode": currentPeriod.PeriodCode,
                    "TribeCode": tribe.UnitCode,
                    "Status_request": "6",
                    "$sort": { DocFormatedDate: -1 }
                });
            let refundsList = [];
            if (refunds.length) {
                // refundsList = handleDuplicateRefunds(refunds);
                handleRefundsTexts(refunds);
            }
            isMounted && setChoosenActivites([]);
            isMounted && setRefundsList(refunds);
        } catch (error) {
            console.log(error);
        }
    }

    const filteredRefunds = useMemo(() => {
        // Apply search filtering to all refunds (with and without DocDate)
        let filtered = refundList.filter(child =>
            (`${child.Pupil_FirstName} ${child.Pupil_lastName}`).includes(debouncedSearchTerm) ||
            child.Pupil_FirstName.includes(debouncedSearchTerm) ||
            child.Pupil_lastName.includes(debouncedSearchTerm) ||
            child.LicTradNum.includes(debouncedSearchTerm)
        );

        // Apply date range filtering only to refunds with DocDate
        filtered = filtered.filter(refund => {
            if (!refund.DocFormatedDate) return true; // Keep refunds without DocDate

            const docDate = new Date(refund.DocFormatedDate); // Assuming refund.DocDate is a valid date string
            if (startDate && docDate < startDate) return false;
            if (endDate && docDate > endDate) return false;

            return true;
        });

        // Apply activity filtering if activities are selected
        if (choosenActivites.length > 0) {
            console.log(choosenActivites)
            filtered = filtered.filter(refund =>
                choosenActivites.some(activ => activ.ActivCode === refund.ActivCode && refund.PeriodCode === currentPeriod.PeriodCode)
            );
        }
        return filtered;
    }, [debouncedSearchTerm, refundList, startDate, endDate, choosenActivites]);

    const periodChange = (value) => {
        enqueueSnackbar(t("refundPeriodMessage"));
        setCurrentPeriod(value);
    }
    // const handleDuplicateRefunds = (refunds) => {
    //     const refundMap = new Map();

    //     refunds.forEach((refund) => {
    //         const key = `${refund.LicTradNum}-${refund.ActivCode}-${refund.PeriodCode}`;

    //         if (refundMap.has(key)) {
    //             // Duplicate found: Append this Payment_Method ID and its description
    //             const existingRefund = refundMap.get(key);

    //             // Extract existing Payment_Method IDs and descriptions
    //             const existingMethods = existingRefund.Payment_Method.split(",");
    //             const existingDescriptions = existingRefund.Payment_Method_Description.split(", ");

    //             // Add the new Payment_Method ID and description if not already present
    //             if (!existingMethods.includes(refund.Payment_Method)) {
    //                 existingMethods.push(refund.Payment_Method);
    //                 existingDescriptions.push(permanentInfo.paymentMethods[refund.Payment_Method]);
    //             }

    //             // Update Payment_Method and Payment_Method_Description with consolidated values
    //             existingRefund.Payment_Method = existingMethods.join(",");
    //             existingRefund.Payment_Method_Description = existingDescriptions.join(", ");
    //         } else {
    //             // No duplicate: Add refund to the map with initial Payment_Method processing
    //             refundMap.set(key, {
    //                 ...refund,
    //                 Payment_Method_Description: permanentInfo.paymentMethods[refund.Payment_Method] || refund.Payment_Method
    //             });
    //         }
    //     });

    //     // Return the refunds list with consolidated Payment_Methods and descriptions for duplicates
    //     return Array.from(refundMap.values());
    // };

    const handleRefundsTexts = (refunds) => {
        const refundActTextMap = {
            "1": t("refundAct1"),
            "2": t("refundAct2"),
        };

        const refundTypeTextMap = {
            "1": t("refundType1"),
            "2": t("refundType2"),
        };

        refunds.forEach(refund => {
            refund.RefundActText = refundActTextMap[refund.RefundAct] || t("noInfo");
            refund.RefundTypeText = refundTypeTextMap[refund.RefundType] || t("noInfo");
            refund.RefundDocTotalText = !!refund.DocTotal ? refund.DocTotal : t("noInfo");
        });
    };

    // Function to handle date selection
    const acceptDates = (newValue, type) => {
        if (type === 'start') {
            setStartDate(newValue);
        } else {
            setEndDate(newValue);
        }
    };

    return (
        <MuiTableContainer>
            <Box display='flex' alignItems='center' sx={{ padding: { xs: '0 1rem', lg: '0' } }}>
                <IconButton color="inherit" ><RequestQuoteOutlinedIcon /></IconButton>
                <Typography ml='4px' variant="title">{`${t('refundForTribe')} ${tribe.UnitName}`}</Typography>
            </Box>
            <Box display='flex' flexWrap='wrap' width={'100%'} sx={{ padding: { xs: '0 1rem', lg: '0' } }}>
                <Box display='flex'>
                    <Autocomplete
                        noOptionsText={t('noPeriodFound')}
                        size="small"
                        clearIcon={false}
                        onChange={(e, value) => { periodChange(value) }}
                        getOptionLabel={(option) => `${option.PeriodName}`}
                        isOptionEqualToValue={(option, anotherOption) => option.PeriodCode === anotherOption.PeriodCode}
                        options={periodsList}
                        value={currentPeriod}
                        renderInput={(params) =>
                            <InputStyle
                                type="period"
                                {...params}
                                label={t('choosePeriodActiv')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {periods.loading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>)
                                }}
                            />}
                        renderOption={(props, pr) => (
                            <li key={pr.PeriodCode} {...props} ><Box key={pr.PeriodCode}><Typography variant="responsiveTextSize">{pr.PeriodName}</Typography></Box></li>
                        )}
                        sx={{ margin: '0.9rem' }} />
                    <Autocomplete
                        multiple={true}
                        limitTags={1}
                        noOptionsText={t('noActivs')}
                        size="small"
                        clearIcon={false}
                        onChange={(e, value) => { setChoosenActivites(value); }}
                        getOptionLabel={(option) => `${option.ActivName}`}
                        isOptionEqualToValue={(option, anotherOption) => option.ActivCode === anotherOption.ActivCode}
                        options={activeList}
                        value={choosenActivites}
                        renderInput={(params) =>
                            <MyInputStyle
                                {...params}
                                label={t('chooseActivs')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {periods.loading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>)
                                }}
                            />}
                        renderOption={(props, pr) => (
                            <li key={pr.ActivCode} {...props} ><Box key={pr.ActivCode}><Typography variant="responsiveTextSize">{pr.ActivName}</Typography></Box></li>
                        )}
                        sx={{ margin: '0.9rem 0.9rem 0rem 0rem' }} />

                </Box>
                <Box display='flex' alignItems="center">
                    <SearchInput
                        sx={{ padding: '0 1rem  0 0' }}
                        placeholder={t('searchPupil')}
                        onChange={e => setSearchTerm(e.target.value)}
                        InputProps={{
                            endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
                        }}
                    />
                </Box>
                <Box display='flex' alignItems="center">
                    <LocalizationProvider adapterLocale={he} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat="dd/MM/yyyy"
                            label={t("startDate")}
                            id="StartDate"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            onAccept={(newValue) => acceptDates(newValue, 'start')}
                            renderInput={(params) => <MyInputStyle {...params} date={true} date1={true} />}
                        />
                        <DatePicker
                            inputFormat="dd/MM/yyyy"
                            label={t("endDate")}
                            id="EndDate"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            onAccept={(newValue) => acceptDates(newValue, 'end')}
                            renderInput={(params) => <MyInputStyle {...params} date={true} date1={false} />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            {getAllRefunds.loading ? <LinearProgress /> :
                <>
                    {!!filteredRefunds.length ?
                        <>
                            <MuiTableContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow key="THrefunds">
                                            <MuiTableCell>{t('theStudentName')}</MuiTableCell>
                                            <MuiTableCell>{t('id')}</MuiTableCell>
                                            <MuiTableCell>{t('activName')} </MuiTableCell>
                                            <MuiTableCell>{t('activDate')}</MuiTableCell>
                                            <MuiTableCell>{t('payedFor')} </MuiTableCell>
                                            <MuiTableCell>{t('payType')} </MuiTableCell>
                                            <MuiTableCell> {t('refundDate')}</MuiTableCell>
                                            {/* <MuiTableCell>{t('refundAct')} </MuiTableCell> */}
                                            <MuiTableCell>{t('refundType')} </MuiTableCell>
                                            <MuiTableCell>{t('refundTotal')} </MuiTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredRefunds.map((refund) => {
                                            return (
                                                <React.Fragment key={refund._id}>
                                                    <TableRow sx={{ padding: '0 0.5rem' }} key={refund._id}>
                                                        <MuiTableCell><Typography variant="responsiveTextSize">{`${refund.Pupil_FirstName} ${refund.Pupil_lastName}`}</Typography></MuiTableCell>
                                                        <MuiTableCell><Typography variant="responsiveTextSize">{refund.LicTradNum} </Typography></MuiTableCell>
                                                        <MuiTableCell><Typography fontWeight='600' variant="responsiveTextSize">{refund.ActivName}</Typography></MuiTableCell>
                                                        <MuiTableCell><Typography variant="responsiveTextSize">{format(new Date(refund.StartDateD), 'dd/MM/yy')}</Typography></MuiTableCell>
                                                        <MuiTableCell><Typography sx={{ direction: 'rtl' }}>{`\u20AA${(+refund.paid).toFixed()}`}</Typography></MuiTableCell>
                                                        <MuiTableCell><Typography>{permanentInfo.paymentMethods[refund.Payment_Method]}</Typography></MuiTableCell>
                                                        <MuiTableCell><Typography variant="responsiveTextSize">{!!refund.DocFormatedDate ? format(new Date(refund.DocFormatedDate), 'dd/MM/yy') : t("noInfo")}</Typography></MuiTableCell>
                                                        {/* <MuiTableCell><Typography variant="responsiveTextSize">{refund.RefundActText}</Typography></MuiTableCell> */}
                                                        <MuiTableCell><Typography variant="responsiveTextSize">{refund.RefundTypeText}</Typography></MuiTableCell>
                                                        <MuiTableCell>
                                                            <Typography variant="responsiveTextSize">
                                                                {refund.RefundDocTotalText ?
                                                                    `${isNaN(+refund.RefundDocTotalText) ? t("noInfo") : `₪${(+refund.RefundDocTotalText).toFixed()}`}`
                                                                    : t("noInfo")
                                                                }
                                                            </Typography>
                                                        </MuiTableCell>
                                                    </TableRow>
                                                </React.Fragment>)
                                        })}
                                    </TableBody>
                                </Table>
                            </MuiTableContainer>
                            <Container sx={{ display: { xs: 'block', sm: 'none' } }}>
                                {filteredRefunds.map((refund, i) => {
                                    return (
                                        <RefundMobileCard
                                            refund={refund}
                                            key={refund._id} />
                                    )
                                })}
                            </Container>
                        </>
                        : <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>
                            <img
                                alt={"..."}
                                src={NoHistory}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }} >{t("noHistoryRefundTribe")}</Typography>
                        </Box>
                    }
                </>}
            {getAllRefunds.error && <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>
                <img
                    alt={"..."}
                    src={NoHistory}
                    width="300px"
                    height="300px"
                />
                <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }} >{t("noHistoryRefundTribe")}</Typography>
            </Box>}
        </MuiTableContainer>
    )
}
const RefundMobileCard = ({ refund }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ padding: '0 0.2rem', margin: "1rem 0", boxShadow: "none", borderBottom: "1px solid black", borderRadius: "0px" }}>
            <Box display='flex' flexDirection="row" alignItems='center' justifyContent='flex-start'>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{refund.Pupil_FirstName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{refund.ActivName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundDate')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{!!refund.DocFormatedDate ? format(new Date(refund.DocFormatedDate), 'dd/MM/yy') : t("noInfo")}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('activDate')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{format(new Date(refund.StartDateD), 'dd/MM/yy')}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundType')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{refund.RefundTypeText}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundAct')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{refund.RefundActText}</Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}
export default TribeRefundsPage;